import styled, { css } from 'styled-components';
import { border, color, layout, position, space, typography, } from 'styled-system';
import { cursor, fontVariantNumeric, hoverBoxShadow, textDecoration, textTransform, transitionDuration, userSelect, whiteSpace, wordBreak, textShadow, } from '../customProperties';
export const TextElement = styled.p `
  ${border};
  ${cursor};
  ${userSelect};
  ${color};
  ${layout};
  ${position};
  ${space};
  ${textShadow};
  ${textDecoration};
  ${textTransform};
  ${transitionDuration};
  ${typography};
  ${fontVariantNumeric};
  ${whiteSpace};
  ${wordBreak};

  &:hover {
    ${hoverBoxShadow};
  }

  ${(props) => props.truncated &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};

  ${(props) => props.truncatedTwo &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `};

  ${(props) => props.wrap &&
    css `
      word-break: break-word;
    `};
`;
