import styled, { css } from 'styled-components';
import { border, color, flexbox, grid, layout, opacity, position, shadow, space, typography, } from 'styled-system';
import theme, { breakpoints } from '@marvelapp/theme';
import { borderBottomLeftRadius, borderTopLeftRadius, borderBottomRightRadius, borderTopRightRadius, cursor, fill, hoverBg, hoverBorderColor, hoverBoxShadow, hoverColor, hoverFill, hoverOpacity, overflow, overflowX, overflowY, pointerEvents, textTransform, transform, transformOrigin, transition, whiteSpace, wordWrap, userSelect, } from './customProperties';
/** @type {any} */
const Box = styled.div `
  ${border};
  ${borderBottomLeftRadius};
  ${borderTopLeftRadius};
  ${borderBottomRightRadius};
  ${borderTopRightRadius};
  ${color};
  ${cursor};
  ${fill};
  ${flexbox};
  ${layout};
  ${opacity};
  ${overflow};
  ${overflowX};
  ${overflowY};
  ${pointerEvents};
  ${position};
  ${shadow};
  ${grid};
  ${space};
  ${textTransform};
  ${userSelect};
  ${transform};
  ${transformOrigin};
  ${transition};
  ${typography};
  ${whiteSpace};
  ${wordWrap};

  box-sizing: border-box;

  @media (min-width: ${breakpoints[0]}) {
    &:hover {
      ${hoverBg};
      ${hoverBorderColor};
      ${hoverBoxShadow};
      ${hoverColor};
      ${hoverFill};
      ${hoverOpacity};
    }
  }

  ${(props) => props.truncated &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
`;
Box.defaultProps = {
    theme,
};
export default Box;
