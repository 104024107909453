import React from 'react';
import theme from '@marvelapp/theme';
import { Svg } from './styles';
/** @param {any} */
function Icon({ paths, children, ...props }) {
    return (React.createElement(Svg, Object.assign({}, props), paths ? paths.map((path) => React.createElement("path", { d: path, key: path })) : children));
}
/** @type {any} */
Icon.defaultProps = {
    theme,
    fill: 'currentColor',
    height: 24,
    viewBox: '0 0 24 24',
    width: 24,
};
export default Icon;
