import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import theme, { easings, CHECKBOX_TICK_ICON } from '@marvelapp/theme';
import Loader from '../Loader';
import Icon from '../Icon';
import { ButtonElement, IconContainer } from './styles';
import { buttonSizes, loaderSizes, iconSizes } from './sizes';
import kinds from './kinds';
/** @param {any} */
function Button({ children, hasSucceeded, isDisabled, isLoading, isRounded, kind, size, tag, translateOnHover = true, ...props }) {
    const disabledProps = isDisabled && {
        ...kinds.disabled,
        disabled: isDisabled,
    };
    const loadingProps = isLoading && {
        bg: 'snow',
        boxShadow: 'button.ghost',
        color: 'transparent',
        disabled: true,
        fill: 'snow',
        hoverColor: 'transparent',
        pointerEvents: 'none',
        position: 'relative',
        textShadow: 'none',
    };
    const succeededProps = hasSucceeded && {
        bg: 'green',
        color: 'transparent',
        disabled: true,
        fill: 'green',
        hoverColor: 'transparent',
        pointerEvents: 'none',
        position: 'relative',
        textShadow: 'none',
    };
    const animation = {
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.7,
                delay: 0.15,
                ease: easings.smooth.array,
            },
        },
        exit: {
            opacity: 0,
            scale: 0,
            transition: {
                duration: 0.6,
                ease: easings.smooth.array,
            },
        },
        initial: {
            opacity: 0,
            scale: 0,
        },
    };
    const icons = (React.createElement(AnimatePresence, null,
        isLoading && (React.createElement(IconContainer, { animate: animation.animate, exit: animation.exit, initial: animation.initial, key: "loader" },
            React.createElement(Loader, Object.assign({}, loaderSizes[size], { testId: "button-loader" })))),
        hasSucceeded && (React.createElement(IconContainer, { animate: animation.animate, exit: animation.exit, initial: animation.initial, key: "tick" },
            React.createElement(Icon, Object.assign({ color: "white", paths: CHECKBOX_TICK_ICON }, iconSizes[size]))))));
    /* Order is important here because the last two objects contain stateful
     * props which should overwrite all the rest */
    const allProps = {
        ...buttonSizes(isRounded)[size],
        ...kinds[kind],
        ...props,
        ...succeededProps,
        ...loadingProps,
        ...disabledProps,
    };
    return (React.createElement(ButtonElement, Object.assign({ as: tag, role: "button", translateOnHover: translateOnHover }, allProps),
        icons,
        children));
}
/** @type {any} */
Button.propTypes = {
    borderRadius: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    boxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    display: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fontSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    fontWeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    hoverBg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hoverBoxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hoverFill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    lineHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    hasSucceeded: PropTypes.bool,
    isLoading: PropTypes.bool,
    /** margin */
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    /** margin-top */
    mt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-right */
    mr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-bottom */
    mb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-left */
    ml: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-left and margin-right */
    mx: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** margin-top and margin-bottom */
    my: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding */
    p: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    /** padding-top */
    pt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-right */
    pr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-bottom */
    pb: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-left */
    pl: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-left and padding-right */
    px: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    /** padding-top and padding-bottom */
    py: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
};
Button.defaultProps = {
    display: 'inline-block',
    fontFamily: 0,
    fontWeight: '500',
    hasSucceeded: false,
    isLoading: false,
    isRounded: true,
    size: 1,
    theme,
};
export default Button;
