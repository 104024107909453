/**
 *
 * Loader
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import Position from '../Position';
import { Spinny, Static } from './styles';
function Loader({ size, color, borderWidth, testId }) {
    return (React.createElement(Position, { "data-testid": testId, display: "block", position: "relative" },
        React.createElement(Static, { borderColor: color, borderWidth: borderWidth, height: size, width: size }),
        React.createElement(Spinny, { borderColor: color, borderWidth: borderWidth, height: size, width: size })));
}
Loader.propTypes = {
    borderWidth: PropTypes.any,
    color: PropTypes.any,
    size: PropTypes.any,
    testId: PropTypes.string,
};
Loader.defaultProps = {
    borderWidth: '4px',
    color: 'silver',
    size: 20,
};
export default Loader;
