import styled from 'styled-components';
import { motion } from 'framer-motion';
import theme from '@marvelapp/theme';
import themeGet from '@styled-system/theme-get';
import { border, color, flexbox, layout, position, shadow, space, typography, } from 'styled-system';
import { activeBoxShadow, fill, hoverBg, hoverBoxShadow, hoverColor, hoverFill, pointerEvents, } from '../customProperties';
/** @type {any} */
export const ButtonElement = styled.button `
  margin: 0;
  padding: 0;

  ${border};
  ${color};
  ${fill};
  ${flexbox};
  ${layout};
  ${pointerEvents};
  ${position};
  ${shadow};
  ${space};
  ${typography};

  appearance: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  outline-style: none;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  transition: all 300ms ${themeGet('easings.smooth.string')};
  will-change: transform;

  &:hover {
    ${hoverBg};
    ${hoverBoxShadow};
    ${hoverColor};
    ${hoverFill};
    transform: ${(props) => props.translateOnHover ? 'translate3d(0px, -1px, 0px)' : 'none'};
  }

  &:active {
    ${activeBoxShadow};
    transform: ${(props) => props.translateOnHover ? 'translate3d(0px, 0px, 0px)' : 'none'};
  }
`;
ButtonElement.defaultProps = {
    theme,
};
export const IconContainer = styled(motion.div) `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
IconContainer.defaultProps = {
    theme,
};
