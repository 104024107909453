import styled, { keyframes } from 'styled-components';
import { color, border, layout } from 'styled-system';
const Static = styled.div `
  ${border};
  ${layout};
  ${color};

  border-radius: 50%;
  border-style: solid;

  opacity: 0.3;
  position: relative;
`;
const spinAnimation = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Spinny = styled.div `
  ${border};
  ${color};
  ${layout};

  animation: ${spinAnimation} 500ms linear infinite;
  border-radius: 50%;
  border-style: solid;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;

  position: absolute;
  top: 0;
  left: 0;
`;
export { Static, Spinny };
